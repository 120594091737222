import styled from 'styled-components';

export const StyledInstagram = styled.div`
  // Set the svg height for IE because it can't do CSS transitions
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    svg {
      height: 93rem;
    }
  }
`;

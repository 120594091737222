import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { loadingInViewOptions } from '../loading';

export const useInViewLoading = (loadNextPage: () => void, isQuerying: boolean) => {
  const [inViewRef, inView] = useInView(loadingInViewOptions);

  useEffect(() => {
    if (inView && !isQuerying) {
      loadNextPage();
    }
  }, [inView, isQuerying]);

  return inViewRef;
};

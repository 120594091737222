import * as qs from 'query-string';
import * as React from 'react';
import { useRouteData } from 'react-static';
import { IS_SERVER } from '../../common/constants';
import { useDebug } from '../../common/hooks/useDebug';
import { useTranslations } from '../../common/translation';
import { HeadTitle } from '../../components/head-title/HeadTitle';
import { RowHeader } from '../../components/row-header/RowHeader';
import { SearchResults } from '../../components/search-results/SearchResults';
import { DefaultLayout } from '../default/DefaultLayout';
import { LocaleProps } from '../default/DefaultLayout.props';
import translations from './translations';

export const searchQueryKey = 'qt';

// noinspection JSUnusedGlobalSymbols
export default () => {
  // This whole component needs window, so return null if on server side
  if (IS_SERVER) {
    return null;
  }

  const { locale, translatedPaths } = useRouteData<LocaleProps>();
  const { t } = useTranslations(translations, locale);

  const queryParams = qs.parse(window.location.search);
  if (!queryParams[searchQueryKey]) {
    // Search facets JSON endpoint requires at least a space
    queryParams[searchQueryKey] = ' ';
    window.history.replaceState({}, '', `?${qs.stringify(queryParams)}`);
  }
  const query = (window.location.search || '').replace(/^\?/, '');
  const appendedPaths = (translatedPaths || []).map((tp) => ({
    ...tp,
    path: `${tp.path}?${qs.stringify(queryParams)}`,
  }));

  useDebug('app:layout:SearchResultsLayout', { props: { locale }, other: { queryParams } });

  return (
    <DefaultLayout locale={locale} translatedPaths={appendedPaths}>
      <HeadTitle title={t('pageTitle', 'Search Results')} />
      <RowHeader
        headingTag='h1'
        title={
          <>
            {t('searchResultsText', 'Search Results')}:{' '}
            <span>{decodeURIComponent(queryParams[searchQueryKey] as string)}</span>
          </>
        }
      />
      <SearchResults key={locale} query={query} showBuyButtons={true} />
    </DefaultLayout>
  );
};

import * as qs from 'query-string';
import { useEffect, useRef, useState } from 'react';
import { logToSentry } from '../../components/catch/Catch';
import { NormalizedFacet, NormalizedSearchResult, SearchOptions } from '../d7/common';

const RESULTS_PER_PAGE = 20;
export const useSearchResults = (query: string, fetchFn: any, options: SearchOptions) => {
  const parsePage = (q: string): number => Number(qs.parse(q).page) || 0;
  const parseSize = (q: string): number => Number(qs.parse(q).size) || RESULTS_PER_PAGE;
  const [error, setError] = useState<Error | undefined>(undefined);
  const [searchResults, setSearchResults] = useState<NormalizedSearchResult[]>([]);
  const [facetsResults, setFacetsResults] = useState<NormalizedFacet[]>([]);
  const [page, setPage] = useState<number>(parsePage(query));
  const [size, setSize] = useState<number>(parseSize(query));
  const [isLoading, setLoadingStatus] = useState<boolean>(true);
  const [isQuerying, setQueryingStatus] = useState<boolean>(false);
  const mountRef = useRef<boolean>(false);

  const finishLoading = () => {
    setQueryingStatus(false);
    setLoadingStatus(false);
  };

  const fetchData = (q: string, concatResults: boolean = true) => {
    (async () => {
      setQueryingStatus(true);
      try {
        const response = await fetchFn(q, options);
        if (mountRef.current) {
          setFacetsResults(response.facets || []);
          setSearchResults((concatResults ? searchResults : []).concat(response.results || []));

          setPage(parsePage(q));
          setSize(parseSize(q));
          finishLoading();
        }
      } catch (e) {
        if (mountRef.current) {
          finishLoading();
          setError(e);
        }
      }
    })();
  };

  useEffect(() => {
    mountRef.current = true;
    setLoadingStatus(true);
    fetchData(query, false);

    return () => {
      mountRef.current = false;
    };
  }, [query]);

  const currentFacet = (): string => {
    const fParam = qs.parse(query)['f[0]'] as string;
    return fParam ? fParam.split(':')[1] : 'all';
  };

  const hasNextPage = (): boolean => {
    const totalRecordsShown = (page + 1) * size;
    const matchingFacet = facetsResults.find((facet) => facet.type === currentFacet());
    return matchingFacet ? matchingFacet.numResults > totalRecordsShown : false;
  };

  const loadNextPage = () =>
    fetchData(
      qs.stringify({
        size,
        ...qs.parse(query),
        page: page + 1,
      }),
    );

  if (error) {
    logToSentry(error);
  }

  return { facetsResults, hasNextPage, isLoading, isQuerying, loadNextPage, page, searchResults };
};

import * as qs from 'query-string';
import * as React from 'react';
import { IS_SERVER } from '../../common/constants';
import { isMultiCountry } from '../../common/countries';
import { getCountryPrefixFromPath } from '../../common/helpers/countrySelection';
import { getLanguagePrefixFromPath, getLocaleFromPath, stripPrefixFromPath } from '../../common/helpers/prefix';
import { useDebug } from '../../common/hooks/useDebug';
import { DEFAULT_LOCALE, prefixedPathsByRoute, useTranslations } from '../../common/translation';
import { HeadTitle } from '../../components/head-title/HeadTitle';
import { RowHeader } from '../../components/row-header/RowHeader';
import { SearchResults } from '../../components/search-results/SearchResults';
import { DefaultLayout } from '../default/DefaultLayout';
import { searchQueryKey } from '../search-results/SearchResultsLayout';
import { StyledPageNotFound } from './404.styles';
import translations from './translations';

const getQueryString = (queryFromPath: string) => {
  const parsedQuery = qs.parse(window.location.search);
  delete parsedQuery[searchQueryKey];

  const modifiedQuery = qs.stringify(parsedQuery) || '';
  const queryString = `${searchQueryKey}=${encodeURI(queryFromPath)}`;
  return modifiedQuery ? `${queryString}&${modifiedQuery}` : queryString;
};

const getLanguageForMarket = (): string => {
  const pathPrefix = isMultiCountry() ? getCountryPrefixFromPath() : getLanguagePrefixFromPath();
  if (pathPrefix) {
    return pathPrefix.substring(1);
  }
  return DEFAULT_LOCALE;
};

// noinspection JSUnusedGlobalSymbols
export default () => {
  // This whole component needs window, so return null if on server side
  if (IS_SERVER) {
    return null;
  }

  const language = getLanguageForMarket();
  const locale = getLocaleFromPath();
  const { t } = useTranslations(translations, locale);

  // Decode URI (taking into account any country/language prefix) and replace non-alphanumeric chars with a space
  const truncatedPath = stripPrefixFromPath(window.location.pathname);
  const queryFromPath: string =
    decodeURI(truncatedPath)
      .replace(/[\W_]+/g, ' ')
      .trim() || ' ';
  const query = getQueryString(queryFromPath);
  const translatedPaths = prefixedPathsByRoute(`${truncatedPath}${window.location.search}`, language);

  useDebug('app:layout:404', { other: { queryFromPath } });

  return (
    <DefaultLayout locale={locale} translatedPaths={translatedPaths}>
      <HeadTitle title={t('pageTitle', 'Page Not Found')} />
      <StyledPageNotFound data-testid='error-message'>
        {`${t(
          'pageNotExistText',
          'The page you requested does not exist. For your convenience, a search was performed using the query',
        )}: ${queryFromPath}`}
      </StyledPageNotFound>
      <RowHeader
        headingTag='h1'
        title={
          <>
            {t('searchResultsText', 'Search Results')}: <span>{queryFromPath}</span>
          </>
        }
      />
      <SearchResults key={language} query={query} showBuyButtons={true} />
    </DefaultLayout>
  );
};

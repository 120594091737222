import * as React from 'react';
import { MouseEvent, useState } from 'react';
import { NormalizedSearchResult } from '../../../common/d7/common';
import { StyledPopupDialog } from '../../../common/popup.styles';
import { BuyPopup } from '../buy-popup/BuyPopup';
import { SearchResultsCard } from './SearchResultsCard';
import { SearchResultsCardsProps } from './SearchResultsCards.props';
import { StyledSearchResultsCards } from './SearchResultsCards.styles';

export const SearchResultsCards = ({
  restrictCount,
  results,
  showBuyButtons,
  showCategories,
}: SearchResultsCardsProps) => {
  const [activeSearchResult, setActiveSearchResult] = useState<NormalizedSearchResult>();
  const titleAriaLabel = 'buy-popup-title';

  const handleOpen = (searchResult: NormalizedSearchResult) => (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveSearchResult(searchResult);
  };

  const handleClose = () => setActiveSearchResult(undefined);
  return (
    <StyledSearchResultsCards data-testid='search-results' restrictCount={restrictCount}>
      {results.map((searchResult: NormalizedSearchResult) => (
        <SearchResultsCard
          key={searchResult.id}
          onClick={handleOpen(searchResult)}
          searchResult={searchResult}
          showCategories={showCategories}
          showBuyButtons={showBuyButtons}
        />
      ))}
      <StyledPopupDialog aria-labelledby={titleAriaLabel} onClose={handleClose} open={!!activeSearchResult}>
        <BuyPopup onClose={handleClose} searchResult={activeSearchResult} titleAriaLabel={titleAriaLabel} />
      </StyledPopupDialog>
    </StyledSearchResultsCards>
  );
};

import { rem } from 'polished';
import styled from 'styled-components';
import { black } from '../../../../common/colors';

export const StyledDesktopSearchFilters = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: ${rem(10)};
`;

export const StyledDesktopSearchFacet = styled.li`
  margin: ${rem(12)} ${rem(18)};

  a {
    display: flex;
    align-items: center;
    height: ${rem(32)};
    font-size: ${rem(14)};
    line-height: 1;
    font-weight: 700;
    color: ${black};

    & > svg {
      width: ${rem(25)};
      height: ${rem(25)};
      margin: 0 ${rem(8)} 0 0;
    }

    &.active,
    &:hover,
    &:focus {
      border-bottom: 2px solid #101010;
      margin-bottom: -2px;
    }
  }
`;

export const StyledFacetsCount = styled.span`
  color: #666666;
  font-size: ${rem(12)};
`;

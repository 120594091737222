import { useEffect, useState } from 'react';

export const useChildrenLoaded = (limit: number, handler?: () => void) => {
  const [loadCount, setLoadCount] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const onChildLoaded = () => {
    setLoadCount(loadCount + 1);
  };

  useEffect(() => {
    if (loadCount >= limit) {
      setIsLoaded(true);
      if (typeof handler === 'function') {
        handler();
      }
    }
  }, [loadCount]);

  return { loadCount, isLoaded, onChildLoaded };
};

import { ClickAwayListener } from '@material-ui/core';
import { Link, navigate } from '@reach/router';
import * as qs from 'query-string';
import * as React from 'react';
import { useState } from 'react';
import DownArrowIcon from '../../../../assets/images/icons/svg/down-arrow.svg';
import WbShieldIcon from '../../../../assets/images/icons/svg/wb-shield.svg';
import { Routes } from '../../../../common/constants';
import { NormalizedFacet } from '../../../../common/d7/common';
import { NavigationIcons } from '../../../../common/helpers/icons';
import {
  isDownKey,
  isEnterKey,
  isEscapeKey,
  isTabBackwardKey,
  isTabForwardKey,
  isUpKey,
} from '../../../../common/helpers/keyboard';
import { getPrefixRedirectedUrl } from '../../../../common/helpers/prefix';
import { useTranslations } from '../../../../common/translation';
import translations from '../../translations';
import { SearchFiltersProps } from '../SearchFilters.props';
import {
  StyledMobileSearchFilters,
  StyledSearchFiltersArrow,
  StyledSearchFiltersContent,
  StyledSearchFiltersHeader,
  StyledSearchFiltersNoContent,
  StyledSearchFiltersOption,
  StyledSearchFiltersOptionList,
  StyledSearchFiltersText,
} from './MobileSearchFilters.styles';

const getCurrentFacetType = () => {
  if (typeof window === 'undefined') {
    return 'all';
  }

  const fParam = qs.parse(window.location.search)['f[0]'] as string;
  return fParam ? fParam.split(':')[1] : 'all';
};

const SearchFacet = ({ facet }: { facet: NormalizedFacet | undefined }) => {
  const { t } = useTranslations(translations);

  if (!facet) {
    return <StyledSearchFiltersNoContent />;
  }

  const FilterIcon = NavigationIcons[facet.type] || WbShieldIcon;

  return (
    <StyledSearchFiltersContent>
      <FilterIcon />
      <StyledSearchFiltersText>
        {t(facet.text, facet.text)} <span>({facet.numResults})</span>
      </StyledSearchFiltersText>
    </StyledSearchFiltersContent>
  );
};

export const MobileSearchFilters = ({ searchFacets }: SearchFiltersProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  if (!(searchFacets || []).length) {
    return null;
  }

  const baseSearchUrl = getPrefixRedirectedUrl(Routes.search);
  const getLinkedURL = (facet: NormalizedFacet) => `${baseSearchUrl}?${facet.query}`;

  const toggleOpen = () => {
    setOpen(!open);
    setActiveIndex(0);
  };
  const handleClose = () => {
    setOpen(false);
    setActiveIndex(0);
  };
  const onHover = (index: number) => () => setActiveIndex(index);
  const incrementIndex = (maxIndex: number) => setActiveIndex(activeIndex >= maxIndex ? 0 : activeIndex + 1);
  const decrementIndex = (maxIndex: number) => setActiveIndex(activeIndex <= 0 ? maxIndex : activeIndex - 1);
  const handleKeyDown = (isOpen: boolean, facets: NormalizedFacet[]) => (e: KeyboardEvent) => {
    if (!isOpen) {
      return;
    }

    const maxIndex = facets.length - 1;
    if (isTabForwardKey(e) || isDownKey(e)) {
      incrementIndex(maxIndex);
    } else if (isTabBackwardKey(e) || isUpKey(e)) {
      decrementIndex(maxIndex);
    } else if (isEnterKey(e)) {
      navigate(getLinkedURL(facets[activeIndex]));
    } else if (isEscapeKey(e)) {
      handleClose();
    } else {
      return;
    }

    e.preventDefault();
  };
  const isActiveOption = (index: number) => index === activeIndex;

  const currentFacetType = getCurrentFacetType();
  const activeFacet = searchFacets.find((facet) => facet.type === currentFacetType);
  const selectableFacets = searchFacets.filter((facet) => facet.type !== currentFacetType);

  return (
    <ClickAwayListener onClickAway={handleClose} touchEvent={false}>
      <StyledMobileSearchFilters>
        <StyledSearchFiltersHeader
          active={open}
          aria-expanded={open}
          aria-haspopup='true'
          onKeyDown={handleKeyDown(open, selectableFacets) as any}
          onClick={toggleOpen}
        >
          <SearchFacet facet={activeFacet} />
          <StyledSearchFiltersArrow active={open}>
            <DownArrowIcon />
          </StyledSearchFiltersArrow>
        </StyledSearchFiltersHeader>
        {selectableFacets.length > 0 && open && (
          <StyledSearchFiltersOptionList role='menu'>
            {selectableFacets.map((facet, index) => (
              <StyledSearchFiltersOption key={facet.query} onMouseMove={onHover(index)} role='none'>
                <Link
                  aria-current={isActiveOption(index) ? 'true' : undefined}
                  role='menuitem'
                  tabIndex={-1}
                  to={getLinkedURL(facet)}
                >
                  <SearchFacet facet={facet} />
                </Link>
              </StyledSearchFiltersOption>
            ))}
          </StyledSearchFiltersOptionList>
        )}
      </StyledMobileSearchFilters>
    </ClickAwayListener>
  );
};

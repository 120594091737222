import * as React from 'react';
import { useEffect, useRef } from 'react';
import CloseIcon from '../../../assets/images/icons/svg/close.svg';
import { widthBreakpoints } from '../../../common/breakpoints';
import { BuyLink, BuyLinkCategory, BuyLinkGroup, NormalizedSearchResult } from '../../../common/d7/common';
import { contentTypeToIcon } from '../../../common/helpers/icons';
import { useChildrenLoaded } from '../../../common/hooks/useChildrenLoaded';
import { useWindowResize } from '../../../common/hooks/useWindowResize';
import {
  StyledCloseButton,
  StyledContentContainer,
  StyledDate,
  StyledDefaultSvgContainer,
  StyledImageContainer,
  StyledPopup,
  StyledTitle,
  StyledTitleContainer,
  StyledType,
  StyledTypeContainer,
  StyledVendorLink,
} from '../../../common/popup.styles';
import { useTranslations } from '../../../common/translation';
import { Img } from '../../img/Img';
import { SuperLink } from '../../super-link/SuperLink';
import translations from '../translations';
import { BuyPopupProps } from './BuyPopup.props';
import {
  spacingLg,
  spacingMd,
  StyledBuyLinksContainer,
  StyledCategoryContainer,
  StyledCategoryTitle,
  StyledGroupContainer,
  StyledGroupTitle,
  StyledLinkImg,
  StyledLinksContainer,
  StyledNonLink,
} from './BuyPopup.styles';

const getTranslationKey = ({ contentType, isMobileGameOrApp }: NormalizedSearchResult) => {
  if (contentType === 'Games & Apps') {
    return isMobileGameOrApp ? 'Mobile Apps' : 'Video Games';
  }

  return contentType;
};

const imageFallback = (contentType: string): JSX.Element => {
  const ContentTypeIcon = contentTypeToIcon[contentType];

  return (
    <StyledDefaultSvgContainer>
      <ContentTypeIcon />
    </StyledDefaultSvgContainer>
  );
};

const Category = ({ name, links, loadHandler }: BuyLinkCategory & { loadHandler?: () => void }) => {
  if (!(links || []).length) {
    if (loadHandler) {
      loadHandler();
    }
    return null;
  }
  const { onChildLoaded } = useChildrenLoaded(links.length, loadHandler);

  return (
    <StyledCategoryContainer>
      {name && <StyledCategoryTitle>{name}</StyledCategoryTitle>}
      <StyledLinksContainer>
        {links.map((link: BuyLink, index) => {
          if (!link) {
            onChildLoaded();
            return null;
          }
          const img = link && link.image && (
            <StyledLinkImg alt={link.image.alt} onError={onChildLoaded} onLoad={onChildLoaded} src={link.image.src} />
          );
          return link.url ? (
            <StyledVendorLink
              aria-label={link.url}
              href={link.url}
              key={`${link.url}_${index}`}
              target='_blank'
              data-wb-vendor-buylink={link.image.alt}
            >
              {img}
            </StyledVendorLink>
          ) : (
            <StyledNonLink key={`${link.image.src}-${link.image.alt}-${index}`}>
              <div className='overlay' />
              {img}
            </StyledNonLink>
          );
        })}
      </StyledLinksContainer>
    </StyledCategoryContainer>
  );
};

const Group = ({ name, categories, loadHandler }: BuyLinkGroup & { loadHandler: () => void }) => {
  if (!(categories || []).length) {
    if (loadHandler) {
      loadHandler();
    }
    return null;
  }

  const { onChildLoaded } = useChildrenLoaded(categories.length, loadHandler);

  return (
    <StyledGroupContainer>
      <StyledGroupTitle>{name}</StyledGroupTitle>
      {categories.map((category: BuyLinkCategory, index) => (
        <Category key={`${name}-${category.name}-${index}`} loadHandler={onChildLoaded} {...category} />
      ))}
    </StyledGroupContainer>
  );
};

export const BuyPopup = ({ onClose, searchResult, titleAriaLabel }: BuyPopupProps) => {
  const buyLinksRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslations(translations);

  /*
   * Set the max height of the links container to allow for scrolling.
   * The exact value of the max height is dependent on the dialog height, which is determined by the content of the
   * links container before applying any max height. Thus, we need to reset the max height each time and only apply
   * the new style in the next iteration of the event loop with setTimeout().
   */
  const setMaxHeight = () => {
    const dialogContainer = document.querySelector('.MuiPaper-root') as HTMLElement;
    if (dialogContainer && buyLinksRef && buyLinksRef.current && titleRef && titleRef.current) {
      let padding: number;
      if (window.innerWidth < widthBreakpoints.mobile) {
        padding = 0;
      } else if (window.innerWidth < widthBreakpoints.tablet) {
        padding = spacingMd;
      } else {
        padding = spacingLg;
      }
      buyLinksRef.current.style.maxHeight = 'none';
      setTimeout(() => {
        buyLinksRef.current!.style.maxHeight = `${dialogContainer.offsetHeight -
          titleRef.current!.offsetHeight -
          padding}px`;
      }, 0);
    }
  };

  const { isLoaded, onChildLoaded } = useChildrenLoaded(searchResult?.buyLinks?.length || 0, setMaxHeight);

  const { windowWidth } = useWindowResize();

  /*
   * Remove unnecessary 'aria-hidden' attribute from root element.
   * It should be sufficient to add 'aria-modal' to this component. In fact, the aXe plugin will complain about
   * all the focusable descendants of the root element unless we do this.
   */
  const clearAriaHidden = () => {
    const root = document.querySelector('#root') as HTMLElement;
    if (root) {
      root.removeAttribute('aria-hidden');
    }
  };

  useEffect(() => {
    clearAriaHidden();
  }, []);

  useEffect(() => {
    setMaxHeight();
  }, [windowWidth]);

  if (!searchResult) {
    return null;
  }

  const { buyLinks, date, contentType, image, title, url } = searchResult;
  if (!(buyLinks || []).length) {
    return null;
  }

  const textKey = getTranslationKey(searchResult);
  const translatedType = t(textKey, textKey);

  return (
    <StyledPopup show={isLoaded} aria-modal={true}>
      <StyledCloseButton onClick={onClose}>
        <CloseIcon />
      </StyledCloseButton>
      <StyledImageContainer>
        <SuperLink to={url}>
          {image && <Img src={image.src} alt={image.alt} />}
          {!image && imageFallback(contentType)}
        </SuperLink>
      </StyledImageContainer>
      <StyledContentContainer>
        <StyledTitleContainer ref={titleRef}>
          <StyledTypeContainer>
            <StyledType>{translatedType}</StyledType>
          </StyledTypeContainer>
          <StyledTitle>
            <SuperLink id={titleAriaLabel} to={url} data-wb-link={titleAriaLabel}>
              {title}
            </SuperLink>
          </StyledTitle>
          {date && <StyledDate>{date}</StyledDate>}
        </StyledTitleContainer>
        <StyledBuyLinksContainer ref={buyLinksRef}>
          {buyLinks!.map((group: BuyLinkGroup, index) => (
            <Group key={`${group.name}_${index}`} loadHandler={onChildLoaded} {...group} />
          ))}
        </StyledBuyLinksContainer>
      </StyledContentContainer>
    </StyledPopup>
  );
};

import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { mediaQueryWidth } from '../../../common/breakpoints';

export const StyledSearchResultsCards = styled.ul<{ restrictCount?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${({ restrictCount }) =>
    restrictCount &&
    css`
      ${mediaQueryWidth({ max: 'tablet' })`
        & > li:nth-of-type(n + 5) {
          display: none;
        }
      `}

      ${mediaQueryWidth({ min: 'tablet' })`
        & > li:nth-of-type(n + 9) {
          display: none;
        }
      `}
    `}

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${rem(308)}, 1fr));
    grid-gap: 10px;

    ${mediaQueryWidth({ min: 'mobile' })`
      grid-gap: 20px;
    `}
  }
`;

export const setClassnameOnActive = (className: string, matchQuery: boolean = false) => ({
  location,
  href,
}: {
  location: Location;
  href: string;
}) => {
  const matched = matchQuery ? location.pathname + location.search === href : location.pathname === href.split('?')[0];

  return matched ? { className } : {};
};

export const setTabIndexForChildElements = (
  parentElementRef: string,
  childNoTabElementRef: string = 'div[aria-hidden=true] a, div[aria-hidden=true] button:not([data-type="router-button"])',
): void => {
  if (document.querySelectorAll) {
    const containers = document.querySelectorAll(parentElementRef);
    const childTabElementRef = childNoTabElementRef.replace(/aria-hidden=true/g, 'aria-hidden=false');
    Array.from(containers).forEach((container: any) => {
      const noTabLinks = container.querySelectorAll(childNoTabElementRef);
      Array.from(noTabLinks).forEach((link: any) => {
        link.setAttribute('tabindex', '-1');
      });
      const tabLinks = container.querySelectorAll(childTabElementRef);
      Array.from(tabLinks).forEach((link: any) => {
        link.removeAttribute('tabindex');
      });
    });
  }
};

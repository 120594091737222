import * as React from 'react';
import WbShieldIcon from '../../../assets/images/icons/svg/wb-shield.svg';
import { contentTypeToIcon } from '../../../common/helpers/icons';
import { useTranslations } from '../../../common/translation';
import { AspectRatioImage } from '../../aspect-ratio-image/AspectRatioImage';
import { Button } from '../../button/Button';
import { SuperLink } from '../../super-link/SuperLink';
import translations from '../translations';
import { SearchResultsCardProps } from './SearchResultsCard.props';
import {
  ContentContainer,
  ImageContainer,
  SearchResultsContent,
  SearchResultsSubtitle,
  SearchResultsText,
  SearchResultsTitle,
  SearchResultsType,
  SearchResultsTypeContainer,
  StyledSearchResultsCard,
  SVGContainer,
} from './SearchResultsCard.styles';

const containsImage = (contentType: string): boolean => contentType === 'Press Releases';
const imageFallback = (contentType: string): JSX.Element => {
  const FallbackIcon = contentTypeToIcon[contentType] || WbShieldIcon;

  return (
    <SVGContainer>
      <FallbackIcon />
    </SVGContainer>
  );
};

export const SearchResultsCard = ({
  onClick,
  showBuyButtons,
  showCategories,
  searchResult: { body, buyLinks, categories, contentType, date, image, isMobileGameOrApp, title, url },
}: SearchResultsCardProps) => {
  const { t } = useTranslations(translations);

  const hasBuyButton = showBuyButtons && (buyLinks || []).length > 0;
  const hasImage = containsImage(contentType);

  const getBuyButtonText = (): string => {
    let textKey: string;
    switch (contentType) {
      case 'Games & Apps':
        textKey = isMobileGameOrApp ? 'buyButtonMobileText' : 'buyButtonVideoGamesText';
        break;
      case 'Movies':
        textKey = 'buyButtonMoviesText';
        break;
      case 'TV Seasons':
        textKey = 'buyButtonTVSeasonsText';
        break;
      case 'TV Series':
        textKey = 'buyButtonTVSeriesText';
        break;
      default:
        textKey = 'buyButtonDefaultText';
    }

    return t(textKey, 'Buy Now');
  };

  const getTypeText = (category: string): string => {
    let textKey: string;
    if (category === 'Games & Apps') {
      textKey = isMobileGameOrApp ? 'Mobile Apps' : 'Video Games';
    } else {
      textKey = category;
    }

    return t(textKey, textKey);
  };

  return (
    <StyledSearchResultsCard fullWidth={hasImage}>
      <SuperLink to={url || '#'} title={title}>
        {!hasImage && (
          <ImageContainer>
            {image && <AspectRatioImage ratio={1.5} image={image} />}
            {!image && imageFallback(contentType)}
          </ImageContainer>
        )}
        <ContentContainer fullWidth={hasImage}>
          <SearchResultsContent>
            <SearchResultsTypeContainer usePlaceholderPadding={showCategories && !categories}>
              {showCategories &&
                categories &&
                categories.split(', ').map((category: string) => (
                  <SearchResultsType key={category} category={category}>
                    {getTypeText(category)}
                  </SearchResultsType>
                ))}
              {!showCategories && <SearchResultsType>{getTypeText(contentType)}</SearchResultsType>}
            </SearchResultsTypeContainer>
            <SearchResultsTitle limitLines={hasBuyButton}>{title}</SearchResultsTitle>
            {date && <SearchResultsSubtitle>{date}</SearchResultsSubtitle>}
            {body &&
              (Array.isArray(body) ? body : [body]).map((text, index) => (
                <SearchResultsText key={`${title}-text-${index}`} limitLines={hasBuyButton}>
                  {text}
                </SearchResultsText>
              ))}
          </SearchResultsContent>
        </ContentContainer>
      </SuperLink>
      {hasBuyButton && (
        <Button
          className='search-card-buy-button'
          classes={{ label: 'search-card-buy-button__label' }}
          fullWidth={true}
          onClick={onClick}
          size='sm'
        >
          {getBuyButtonText()}
        </Button>
      )}
    </StyledSearchResultsCard>
  );
};

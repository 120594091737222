import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../../common/breakpoints';
import { lightGrey7 } from '../../../common/colors';
import {
  desktopLinkHeight,
  desktopLinkWidth,
  gap,
  mobileLinkHeight,
  mobileLinkWidth,
  tabletLinkWidth,
} from '../../../common/popup.styles';
import { Img } from '../../img/Img';

export const spacingXs = 5;
export const spacingSm = 10;
export const spacingMd = 20;
export const spacingLg = 40;

export const StyledBuyLinksContainer = styled.div`
  padding: 0 ${spacingMd - 2}px;
  overflow-y: auto;
  box-sizing: border-box;

  ${mediaQueryWidth({ min: 'mobile' })`
    padding: 0 ${spacingMd}px 0 ${spacingXs}px;
  `}
`;

export const StyledGroupContainer = styled.div`
  padding: ${spacingMd}px 0;

  ${mediaQueryWidth({ min: 'tablet' })`
    padding: ${spacingMd}px 0;
  `}
`;

export const StyledGroupTitle = styled.div`
  font-weight: 800;
  font-size: ${rem(17)};
  line-height: ${rem(22)};

  ${mediaQueryWidth({ min: 'tablet' })`
    font-size: ${rem(20)};
    line-height: ${rem(24)};
  `}
`;

export const StyledCategoryContainer = styled.div``;

export const StyledCategoryTitle = styled.div`
  margin: 10px 0 0 0;
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  font-weight: 600;
`;

export const StyledLinksContainer = styled.div`
  line-height: 0;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fill, ${mobileLinkWidth}px);
    grid-gap: ${gap}px;
    margin-top: ${gap}px;

    ${mediaQueryWidth({ min: 'mobile', max: 'tablet' })`
      grid-template-columns: repeat(auto-fill, ${mobileLinkWidth}px);
    `}

    ${mediaQueryWidth({ min: 'tablet', max: 'desktop' })`
      grid-template-columns: repeat(auto-fill, ${tabletLinkWidth}px);
    `}

    ${mediaQueryWidth({ min: 'desktop' })`
      grid-template-columns: repeat(auto-fill, ${desktopLinkWidth}px);
    `}
  }
`;

export const StyledNonLink = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${lightGrey7};

  @media (-ms-high-contrast: none), (-ms-high-contrast: active), @supports not (display: grid) {
    margin: ${gap}px ${gap}px 0 0;
  }

  & > .overlay {
    background-color: #000;
    height: 100%;
    opacity: 0.05;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
  }

  & > img {
    display: block;
    opacity: 0.5;
    filter: grayscale(100);
  }
`;

export const StyledLinkImg = styled(Img)`
  max-height: ${mobileLinkHeight}px;
  max-width: 100%;

  ${mediaQueryWidth({ min: 'mobile' })`
    max-height: ${desktopLinkHeight}px;
  `}
`;

import * as React from 'react';
import { NormalizedSearchResult } from '../../common/d7/common';
import { getSearchResults } from '../../common/d7/search';
import { useInViewLoading } from '../../common/hooks/useInViewLoading';
import { useSearchResults } from '../../common/hooks/useSearchResults';
import { StyledHiddenTrigger, StyledLoader } from '../../common/loading';
import { useTranslations } from '../../common/translation';
import { InstagramLoader } from '../instagram-loader/InstagramLoader';
import { SearchFilters } from './search-filters/SearchFilters';
import { SearchResultsCards } from './search-results-card/SearchResultsCards';
import { SearchResultsProps } from './SearchResults.props';
import { StyledSearchNoResults, StyledSearchResults } from './SearchResults.styles';
import translations from './translations';

export const SearchResults = ({
  hiddenResults,
  hideFacets,
  preventLoadMore,
  query,
  restrictCount,
  showBuyButtons,
  showCategories,
  useTopPadding,
  orderField,
}: SearchResultsProps) => {
  const { locale, t } = useTranslations(translations);
  const { facetsResults, hasNextPage, isLoading, isQuerying, loadNextPage, searchResults } = useSearchResults(
    query,
    getSearchResults,
    { locale, orderField },
  );
  const inViewRef = useInViewLoading(loadNextPage, isQuerying);

  return (
    <StyledSearchResults className='search-results-container' useTopPadding={useTopPadding}>
      {isLoading && <InstagramLoader />}
      {!isLoading && (searchResults || []).length === 0 && (
        <StyledSearchNoResults>{t('noResultsText', 'No results')}</StyledSearchNoResults>
      )}
      {!isLoading && (searchResults || []).length > 0 && (
        <>
          {!hideFacets && <SearchFilters searchFacets={facetsResults} />}
          <SearchResultsCards
            restrictCount={restrictCount}
            results={searchResults.filter(
              ({ id }: NormalizedSearchResult) => !(hiddenResults && id && hiddenResults.includes(id)),
            )}
            showBuyButtons={showBuyButtons}
            showCategories={showCategories}
          />
          {!preventLoadMore && hasNextPage() && (
            <>
              <StyledHiddenTrigger ref={inViewRef} />
              <StyledLoader isVisible={isQuerying} />
            </>
          )}
        </>
      )}
    </StyledSearchResults>
  );
};

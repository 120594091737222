import { Link } from '@reach/router';
import * as React from 'react';
import { Routes } from '../../../../common/constants';
import { NormalizedFacet } from '../../../../common/d7/common';
import { setClassnameOnActive } from '../../../../common/helpers/dom';
import { NavigationIcons } from '../../../../common/helpers/icons';
import { getPrefixRedirectedUrl } from '../../../../common/helpers/prefix';
import { useTranslations } from '../../../../common/translation';
import translations from '../../translations';
import { SearchFiltersProps } from '../SearchFilters.props';
import { StyledDesktopSearchFacet, StyledDesktopSearchFilters, StyledFacetsCount } from './DesktopSearchFilters.styles';

export const DesktopSearchFilters = ({ searchFacets }: SearchFiltersProps) => {
  const { t } = useTranslations(translations);

  if (!(searchFacets || []).length) {
    return null;
  }

  const baseSearchUrl = getPrefixRedirectedUrl(Routes.search);

  const SearchFacet = ({ query, type, text, numResults }: NormalizedFacet) => {
    const NavigationIcon = NavigationIcons[type] || (() => null);

    return (
      <StyledDesktopSearchFacet>
        <Link to={`${baseSearchUrl}?${query}`} className={type} getProps={setClassnameOnActive('active', true)}>
          <NavigationIcon />
          <div>
            {t(text, text)} <StyledFacetsCount>({numResults})</StyledFacetsCount>
          </div>
        </Link>
      </StyledDesktopSearchFacet>
    );
  };

  return (
    <StyledDesktopSearchFilters>
      {searchFacets.map((searchFacetProps: NormalizedFacet) => (
        <SearchFacet key={searchFacetProps.text} {...searchFacetProps} />
      ))}
    </StyledDesktopSearchFilters>
  );
};

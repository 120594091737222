import * as React from 'react';
import { useWindowResize } from '../../../common/hooks/useWindowResize';
import { DesktopSearchFilters } from './desktop/DesktopSearchFilters';
import { MobileSearchFilters } from './mobile/MobileSearchFilters';
import { SearchFiltersProps } from './SearchFilters.props';

export const SearchFilters = ({ searchFacets }: SearchFiltersProps) => {
  const { isSmallScreen } = useWindowResize();

  return isSmallScreen ? (
    <MobileSearchFilters searchFacets={searchFacets} />
  ) : (
    <DesktopSearchFilters searchFacets={searchFacets} />
  );
};

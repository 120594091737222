import { rem } from 'polished';
import styled from 'styled-components';
import { hoverMediaQuery } from '../../../../common/breakpoints';
import { black, lightBlue20, white } from '../../../../common/colors';

export const StyledMobileSearchFilters = styled.div`
  position: relative;
  z-index: 1;
  margin-bottom: 15px;
`;

export const StyledSearchFiltersHeader = styled.button<{ active?: boolean }>`
  display: block;
  position: relative;
  margin: 0 0 5px 0;
  width: 100%;
  padding: 0;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;

  ${({ active }) =>
    active
      ? `
        background-color: ${white};
        border-color: ${lightBlue20};
      `
      : `
        background-color: #f7f7f7;
        border-color: #e5e5e5;

        ${hoverMediaQuery} {
          &:hover {
            background-color: ${white};
            border-color: ${black};
          }
        }

        &:focus {
          background-color: ${white};
          border-color: ${lightBlue20};
        }
      `}
`;

export const StyledSearchFiltersContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;

  & > svg {
    width: 16px;
    height: 16px;
    margin-right: 20px;
  }
`;

export const StyledSearchFiltersNoContent = styled.div`
  height: 16px;
  padding: 10px 20px;
`;

export const StyledSearchFiltersArrow = styled.div<{ active?: boolean }>`
  position: absolute;
  top: 0;
  right: 10px;

  & > svg {
    width: 14px;
    height: 36px;
    ${({ active }) =>
      active &&
      `
        color: ${lightBlue20};
        transform: rotate(180deg);
      `}
  }
`;

export const StyledSearchFiltersOptionList = styled.ul`
  position: absolute;
  left: 0;
  right: 0;
  padding: 5px 0;
  background-color: ${white};
  border: 2px solid #101010;
`;

export const StyledSearchFiltersOption = styled.li`
  & > a {
    display: block;
    outline: none;

    svg {
      color: ${black};
    }

    &[aria-current='true'] {
      background-color: #f2fbff;

      svg {
        color: #005aa3;
      }
    }
  }
`;

export const StyledSearchFiltersText = styled.div`
  font-size: ${rem(14)};
  line-height: ${rem(16)};
  font-weight: 700;
  color: ${black};

  & > span {
    color: #666666;
  }
`;

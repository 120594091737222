import { rem } from 'polished';
import styled from 'styled-components';
import { black, getTagColor, lightGrey2, lightGrey6, white } from '../../../common/colors';
import { Button } from '../../button/Button';

const imageWidthPx = 120;
const contentPaddingPx = 10;

export const StyledSearchResultsCard = styled.li<{ fullWidth?: boolean }>`
  position: relative;
  height: ${rem(180)};
  border: 1px solid ${lightGrey6};

  @media (-ms-high-contrast: none), (-ms-high-contrast: active), @supports not (display: grid) {
    flex: 1 0 ${rem(308)};
    max-width: ${rem(616)};
    margin-left: 20px;
    margin-top: 20px;
  }

  & > a {
    display: block;
    height: 100%;
    line-height: 0;
    cursor: pointer;
    color: ${black};

    &:hover,
    &:focus {
      outline: 1px solid #000000;
      box-shadow: 0 20px 20px -8px #dbdbdb;
    }

    img {
      line-height: 1;
    }

    * {
      cursor: pointer;
    }
  }

  && > ${Button} {
    position: absolute;
    right: ${rem(contentPaddingPx)};
    bottom: ${rem(contentPaddingPx)};
    width: ${({ fullWidth }) =>
      fullWidth
        ? `calc(100% - ${rem(2 * contentPaddingPx)})`
        : `calc(100% - ${rem(imageWidthPx + 2 * contentPaddingPx)})`};
    .search-card-buy-button__label {
      pointer-events: none;
    }
  }
`;

export const ImageContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  width: ${rem(imageWidthPx)};
  height: 100%;
`;

export const ContentContainer = styled.section<{ fullWidth?: boolean }>`
  display: inline-block;
  vertical-align: top;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : `calc(100% - ${rem(imageWidthPx)})`)};
  height: 100%;
  padding: ${rem(contentPaddingPx)};
  box-sizing: border-box;
`;

export const SearchResultsType = styled.div<{ category?: string }>`
  display: inline-block;
  padding: ${rem(2)} ${rem(4)};
  font-size: ${rem(10)};
  font-weight: 400;
  line-height: ${rem(13)};
  color: ${black};
  background-color: ${({ category }) => getTagColor(category)};
`;

export const SearchResultsTypeContainer = styled.div<{ usePlaceholderPadding?: boolean }>`
  ${({ usePlaceholderPadding }) => (usePlaceholderPadding ? `padding-top: ${rem(20)};` : `margin-bottom: ${rem(2)};`)}

  & > ${SearchResultsType} {
    margin-bottom: ${rem(3)};

    &:not(:last-of-type) {
      margin-right: ${rem(5)};
    }
  }
`;

export const SearchResultsTitle = styled.section<{ limitLines?: boolean }>`
  color: ${black};
  font-size: ${rem(16)};
  line-height: ${rem(19)};
  font-weight: 800;
  display: block;
  word-wrap: break-word;
  hyphens: auto;

  ${({ limitLines }) =>
    limitLines &&
    `
      @supports (-webkit-line-clamp: inherit) or (-moz-line-clamp: inherit) {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -moz-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      // Fallback for line clamp
      @media (-ms-high-contrast: none),
        (-ms-high-contrast: active),
        @supports (not ((-webkit-line-clamp: inherit) or (-moz-line-clamp: inherit))) {
        position: relative;
        max-height: ${rem(38)};
        overflow: hidden;

        &:before {
          content: '...';
          position: absolute;
          right: 0;
          bottom: 0;
          background: ${white};
        }

        &:after {
          content: '';
          position: absolute;
          right: 0;
          width: 1em;
          height: ${rem(19)};
          background: ${white};
        }
      }
    `}
`;

export const SearchResultsSubtitle = styled.div`
  font-size: ${rem(10)};
  line-height: ${rem(13)};
  font-weight: 400;
  color: #595959;
  margin-top: ${rem(5)};
`;

export const SearchResultsText = styled.p<{ limitLines?: boolean }>`
  font-size: ${rem(12)};
  line-height: ${rem(15)};
  font-weight: 400;
  margin: ${rem(5)} 0 0 0;

  @supports (-webkit-line-clamp: inherit) or (-moz-line-clamp: inherit) {
    display: -webkit-box;
    -webkit-line-clamp: ${({ limitLines }) => (limitLines ? 1 : 5)};
    -moz-line-clamp: ${({ limitLines }) => (limitLines ? 1 : 5)};
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  // Fallback for line clamp
  @media (-ms-high-contrast: none),
    (-ms-high-contrast: active),
    @supports (not ((-webkit-line-clamp: inherit) or (-moz-line-clamp: inherit))) {
    position: relative;
    max-height: ${({ limitLines }) => (limitLines ? rem(15) : rem(75))};
    overflow: hidden;

    &:before {
      content: '...';
      position: absolute;
      right: 0;
      bottom: 0;
      background: ${white};
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      width: 1em;
      height: ${rem(15)};
      background: ${white};
    }
  }
`;

export const SearchResultsContent = styled.div`
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  & > * {
    flex: 0 0 auto;
  }
`;

export const SVGContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > svg {
    width: 90px;
    color: ${lightGrey2};
  }
`;

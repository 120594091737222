import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { StyledPaddingContainer } from '../../common/padding.styles';

export interface StyledSearchResultsProps {
  useTopPadding?: boolean;
}

export const StyledSearchResults = styled.section<StyledSearchResultsProps>`
  background-color: white;
  padding: 0 10px;

  ${mediaQueryWidth({ min: 'mobile' })`
    padding: 0 60px;
  `}

  ${({ useTopPadding }) =>
    useTopPadding &&
    css`
      padding-top: 10px;

      ${mediaQueryWidth({ min: 'mobile' })`
        padding-top: 50px;
      `}
    `}

  & > button {
    display: block;
    margin: 10px auto 0 auto;

    ${mediaQueryWidth({ min: 'mobile' })`
      margin: 40px auto 0 auto;
    `}
  }
`;

export const StyledSearchNoResults = styled(StyledPaddingContainer)<StyledSearchResultsProps>`
  padding-bottom: ${rem(32)};
`;
